/*
** App -- CSS overall for Squishy Electron
** Copyright (C) 2021-2023 Tactile Interactive, all rights reserved
*/

/* **************************************** general */

* {
	box-sizing: border-box;
}

body {
	background-color: #222;
	font-size: 16px;
	font-family: verdana, helvetica, sans-serif;
	color: #eee;
	margin: 0;
	padding: 0;
}

// format as a variable
var {
	font-family: charter, palatino, hoefler text, georgia, baskerville, times, times new roman, serif;
	// default italic
}

small {
	font-size: .8em;  // one step smaller
}

big {
	font-size: 1.25em;  // one step larger
}

/* **************************************** app outer components */

h2.App-header {
	text-align: center;
	color: black;
	background-color: white;
	background-image: linear-gradient(#fef, #fee, #ffe, #efe, #eff, #eef);
	padding: 0 0;
	position: relative;

	.fontSizer {
		position: absolute;
		bottom: 0;
		right: 6px;
		font-weight: normal;
		color: #aaa;
		span {
			cursor: pointer;
		}
	}

}

.splatImage {
	vertical-align: middle;
}

footer {
	height: 3em;
	padding: 0 2em;
	margin: 1em 0 0;
}
#emscriptenLogo
{
	float: right;
	height: 100px;
	margin: -31.25px -43.75px  // white border too wide
}
#webassemblyLogo, #webglLogo, #openglLogo
{
	float: right;
	height: 50px;
	margin-right: 1em;
}

/* **************************************** controls in general */

/* applies to text boxes, checkboxes, radios, sliders, ... */
input {
	background-color: transparent;
	color: #eee;
}

input[type=range] {
	//height: 4em;
// 	background-color: #4cf;
// 	color: #fc4
}

.LogSlider {
	position: relative;

	// bar on the top that shows numerical setting & min & max
	aside {
		display: flex;
		padding: .2px;

		.left {
			flex: 1 0 1em;
			text-align: left;  // does it do anything?
			flex: 1 0 4em;
			text-align: left;
		}

		.middle {
			flex: 2 0 10em;
			text-align: center;
		}

		.right {
			flex: 1 0 1em;
			text-align: right;  // does it do anything?
			flex: 1 0 4em;
			text-align: right;
		}
    }

	input[type=range]{
		margin: 0;
		width: 100%;
		accent-color: lime;
	}
}

.TextNSlider {
	span {
		line-height: 2em;
	}
	input[type=number] {
		background-color: #222;
		height: 2em;
	// 	background-color: #4cf;
		color: #fc4;
		margin: 0 .5em;
		text-align: right;
	}
	input[type=range] {
		display: block;
		margin-bottom: -.5em;
		accent-color: #f80;
		height: 2em;
	// 	background-color: #4cf;
	// 	color: #fc4
	}
}

// buttons in general
button {
	padding: .2em 1em;
	margin: .2em;
	font-size: 100%;
	border-radius: .5em;

	cursor: pointer;
	background-color: #fff;
	color: #004;

	// hint that you can click it
	&:hover {
		background-color: #cef;
	}

	// kinda reverse video to make an impact
	&:active {
		background-color: #048;
		color: #fff;
		border-style: inset;
	}
}

/* **************************************** CommonDialog */

dialog#CommonDialog {
	// let the specific kind of dialog decide the border, padding, background, etc
	padding: 0;
	line-height: 1.5em;

	// google recommends this for their polyfill to 'center' it in the screen
	// except it should be 1/3 of the way down, not 1/2
	top: 40%;  // move to 1/3 down.  Well, actually more
	transform: translate(0, -33%);  // then make that location 1/3 of the way down the page

	margin-top: 0;  // by default this is like -145!?!?!

	border: none;
	background-color: white;
	color: black;
	box-shadow: 5px 5px 5px #0008;

	&::backdrop {
		background-color: #ddd8;
	}

	// dialogPolyfill
	+ .backdrop {
		background-color: #ddd8;
	}


	label {
		display: block;
		margin: 1em 0;
	}

	input[type=radio] {
		margin: 0 1em 0 0;
	}

	input[type=number] {
		color: #ddf;
		margin: 0;
	}

	input[type=range] {
		color: #8cf;
		background-color: #888;
		margin: 0;
	}

	button {
		margin: .5em;
	}

	tr td {
		text-align: right;
	}

	.dialogSection {
		margin: 1em 0 0 0;
	}

	// the insides of various dialogs
	#SimpleDialog, #ErrorDialog {
		padding: 1em;
		border: 1px #ddd outset;
		height: 100%;
		background-color: #fff;

		p {
			min-width: 30em;
		}
		nav {
			text-align: right;
			button {
				text-align: center;
				margin-top: 2em;
				padding: 1em 4em;
			}
		}
	}

	#SimpleDialog {
		background: #444;
		color: #ddd;
	}
	#ErrorDialog {
		background: #800;
		color: #ffe;
	}

}

// add this to the upper right of dialogs
.x_close_box {
	position: absolute;
	top: 0;
	right: 0;
	font-size: 2em;
	padding: 0;
	margin: 0;
	border: none;
	border-radius: 0;
	line-height: .5em;
	margin: .5em;
	background-color: transparent;
}

// this strange experiment that I haven't touched in at least a year
.runningOneCycle {
	float: right;
	margin: 1em;
	border: outset 3px #ca8;
	background-color: #468;
	padding: .5em;
}

.SquishPanel {
	position: relative;
	z-index: 10;
}

