/*
** Error Boundary -- css for ErrorBoundary.  get it going someday!
** Copyright (C) 2022-2023 Tactile Interactive, all rights reserved
*/


#g2 .ErrorBoundaryReport {
	text-align: left;
	color: #eef;
	background-color: #a00;
	padding: 1em;
	overflow: auto;
	font-weight: bold;
	font-size: 16px;

	.rightSide {
		float: right;
		width: 30em;
		font-size: 1.5em;

		button {
			font-size: 1em;
			float: right;
			margin: 4px;
		}
	}

	/* src code pointers in tracebacks */
	a {
		color: #ff8;
	}

	.messageBox {
		background-color: #fee;
		color: #400;
		padding: 1em;
		font-size: 1.4em;
	}

	.traceback
	{
		font-size: 16px;
		white-space: pre-wrap;
	}
}
