/*
** Common Dialog -- general dialog framework for Squishy Electron
** Copyright (C) 2023-2023 Tactile Interactive, all rights reserved
*/

@use "../widgets/common.scss";



#DocReader {
	padding: 1em;
	width: 100%;
	height: 100%;

	iframe {
		border: none;
		background-color: common.$docReaderBackground;
	}
}

