/*
** common scss -- scss variables app-wide
** Copyright (C) 2023-2023 Tactile Interactive, all rights reserved
*/
// Included all over.  Do not put actual CSS in here, otherwise it's duplicated.


$voltageColor: #fec;
$voltageLighterColor: #ffe;
$voltageDarkerColor: #b94;

$sizeBoxSize: 32px;

$docReaderBackground: #fff;
