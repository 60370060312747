/*
** Control Panel -- everything under the WaveView component (almost)
** Copyright (C) 2021-2023 Tactile Interactive, all rights reserved
*/
@use "../widgets/common.scss";

.ControlPanel {
	// with the tabs
	.cpSecondRow {
		display: flex;
		flex-direction: row;
	}

	.subPanel {
		flex: 0 0 10em;
	}


	/* **************************************** all log sliders */

	/* make the overall any width you want. */
	.LogSlider {
		padding: .2em;

		aside {
			display: flex;
		}

		input[type=range] {
			background-color: #48f;
			color: #fc4
		}
	}



	.CPToolbar {
		float: none;

		.frameRateBox {
			display: inline-block;
			margin-left: .5em;
		}

		.rateSelector {
			background-color: #222;
			color: #eee;
		}

		.toolbarThing {
			display: inline-block;
		}

		/* **************************************** toolbar buttons */

		.startStopTool {
			display: inline-block;
			font-size: 2em;
			padding: .1em .5em .2em;
			margin: .1em .05em;

			background: linear-gradient(#889, #dcd 20%, #ccd 25%, #cdd 30%, #223);
			border: none;
			border-radius: 0;
			color: #fff;
		}

		button.startStopTool:active {
			background: linear-gradient(#223, #cdd 70%, #ccd 75%, #dcd 80%, #889);
		}

		.startStopToggle {
			width: 8em;
		}

		.stepButton {
			width: 4em;
		}



		/* **************************************** other */

		.cpSlider {
			display: inline-block;
			width: 26em;
			padding: 0 .5em 0 .5em;
		}

		// set the width inline
		.toolSpacer {
			display: inline-block;
		}
	}


	/* **************************************** Tab bar & Tabs */

	/* the vertical bar on the left */
	ul.TabBar {
		margin: 0;
		padding: 0;
		position: absolute;
		z-index: 10;
		position: relative;
		cursor: pointer;

		/* a tab button */
		li {
			list-style: none;
			display: block;
			border: outset #abc 2px;
			border-radius: .5em 0 0 .5em;
			padding: .2em .5em .2em 1em;
			width: calc(7em + 2px);
			margin: 0 0 .2em .5em;
			text-align: right;
		}

		li.selected {
			background-color: #eef;
			color: #222;
			border-style: inset;
			border-color: #abc;
			border-right: solid #eef 2px;
			width: 7.2em;
		}
	}


	.tabFrame {
		width: 90%;
		border: inset #abc 2px;
		padding: .2em .5em;
		color: #222;
		background-color: #eef;
		margin-left: -.2em;

		h3 {
			margin: .2em;
		}
		h4 {
			margin: .2em;
			display: inline-block;

			width: 6em;
			text-align: right;
		}
	}


	//.MiniGraph {
	//border: #888 solid 1px;
	//background: #222;
	//}


	/* **************************************** Set Wave tab */

	/* a column in the SetWave band */
	.waveTabCol {
		float: left;
		text-align: right;
		padding: 0 .5em;

		&.middle  label {
			display: block;
			text-align: right;
		}

		input {
			display: inline;
		}

		.selected {
			background: #ccf;
		}
	}


	/* **************************************** Set Voltage tab */

	.setVoltageTab {
		width: calc( 50em + 200px );
		height: 8em;

		input[type=range]{
			accent-color: common.$voltageDarkerColor;
		}

		.divider {
			float: left;
			height: 6em;
			border-left: 1px #888 solid;
			margin: 1em .5em;
		}

		.voltageTitlePanel {
			float: left;
			width: 14em;
		}

		.breedSelector {
			padding: 1em 0;
		}

		.voltageCanyonPanel {
			float: left;
			width: 24em;
		}

		.miniGraphPanel {
			display: grid;
			grid-template-columns: auto auto auto;
			grid-template-rows: auto auto;
			gap: .5em;
			width: 450px;

			// will trigger 2 warnings, it's ok, ignore it as long as it still works
			// "The keyword 'slider-vertical' ... is not standardized..."
			.canyonScale, .canyonPower {
				appearance: slider-vertical;
				width: 2.5em;
			}

			.canyonOffset {
				height: 20px;
			}

			.powerDisplay {
				width: 2.5em;
				text-align: center;
			}

			.scaleDisplay {
				width: 4em;
				text-align: center;
				font-size: .7em;
			}

			.miniGraph {
				// width and height passed in from JS
				border: #aaa 1px outset;
				stroke: common.$voltageColor;
				stroke-width: 1;
				pointer-events: none;
				background-color: #000;
			}
		}
	}


	/* **************************************** Set Integration tab */

	.SetIntegrationTab {
		.sliderBlock, .iStats {
			width: 40%;
			float: left;
			margin-left: 3em;
		}

		.sliderBlock .middle {
			width: 10em;
		}

		.iStats table {
			float: left;
			margin-left: 3em;

			td {
				text-align: right;

				&:first-child {
					font-size: .7em;
				}
				&:last-child {
					width: 7em;
					font-weight: normal;
				}
			}
		}
	}

}

/* **************************************** Set Resolution tab & dialog */

.setResolutionButton {
	float: right;
}

.ResolutionDialog {
	h3 { margin-top: 0; }
	.spaceLength {
		input {
			width: 6em;
			text-align: right;
		}
	}
	padding: 1em;
	margin: 0 auto;
	label {
		margin: .5em;
	}

	background-color: black;
	color: white;

	display: grid;
	gap: .5em;
	h3 {
		grid-area: header;
		text-align: center;
	}
	.resolutionSlider { grid-area: resolutionSlider }
	.continuum {
		grid-area: continuum;
		label.contENDLESS {  }
		label.contWELL {  }
	}
	.spaceLength { grid-area: spaceLength; }
	.cancelButton { grid-area: cancelButton }
	.okButton { grid-area: okButton }
	@media screen and (max-width: 599px) {
		grid-template-columns: auto auto;
		grid-template-areas:
			"header header"
			"resolutionSlider resolutionSlider"
			"continuum continuum"
			"cancelButton okButton";

		.spaceLength { display: none; }
		.continuum {
			.continuumTitle { display: none};
			display: flex;
			label {flex: 1 1 0;}
		}
		label {
			padding: 0;
			small { display: none }
		}
	}
	@media screen and (min-width: 600px) {
		grid-template-columns: auto auto auto ;
		grid-template-areas:
			"header header header header"
			"resolutionSlider resolutionSlider resolutionSlider resolutionSlider"
			"continuum .   spaceLength spaceLength"
			"continuum .  spaceLength spaceLength"
			". . cancelButton okButton ";

		.continuum label {
			display: block;
			padding: 0;
			small { display: block }
		}
	}

}

